<p-toast></p-toast>
<div class="cmn-container">
    <p-tabView [(activeIndex)]="activeIndex" (onChange)="onTabChange($event); updateFormValidator()" class="custom-tabview">
        <p-tabPanel [ngClass]="{ 'active-tab': activeIndex === 0 }" header="Basic">
            <form [formGroup]="schoolBasicForm">
                <div class="form-card">
                    <div class="row gap-y-30px">
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input class="w-full" formControlName="orgName" pInputText (change)="onValueChange()" />
                                <label>School Display Name</label>
                            </span>
                            <div *ngIf="getSchoolBasicFormControls['orgName'].errors && (getSchoolBasicFormControls['orgName'].dirty || getSchoolBasicFormControls['orgName'].touched)"
                                class="invalid-feedback">
                                <div *ngIf="getSchoolBasicFormControls['orgName'].errors['required']">School Display
                                    Name is
                                    required.</div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="d-flex gap-2">
                                <div class="">
                                    <span class="p-float-label cus-float-label">
                                        <input formControlName="administratorFirstName" (change)="onValueChange()" class="w-full" pInputText />
                                        <label>Admin First Name</label>
                                    </span>
                                    <div *ngIf="getSchoolBasicFormControls['administratorFirstName'].errors && (getSchoolBasicFormControls['administratorFirstName'].dirty || getSchoolBasicFormControls['administratorFirstName'].touched)"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="getSchoolBasicFormControls['administratorFirstName'].errors['required']">
                                            Admin First Name is
                                            required.</div>
                                    </div>
                                </div>
                                <div class="">
                                    <span class="p-float-label cus-float-label">
                                        <input formControlName="administratorLastName" (change)="onValueChange()" class="w-full" pInputText />
                                        <label>Admin Last Name</label>
                                    </span>
                                    <div *ngIf="getSchoolBasicFormControls['administratorLastName'].errors && (getSchoolBasicFormControls['administratorLastName'].dirty || getSchoolBasicFormControls['administratorLastName'].touched)"
                                        class="invalid-feedback">
                                        <div
                                            *ngIf="getSchoolBasicFormControls['administratorLastName'].errors['required']">
                                            Admin Last Name is
                                            required.</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="d-flex gap-2">
                                <div class="">
                                    <span class="p-float-label cus-float-label">
                                        <input class="w-full" formControlName="shippingState" pInputText
                                            (change)="onValueChange()" />
                                        <label>State</label>
                                    </span>
                                    <div *ngIf="getSchoolBasicFormControls['shippingState'].errors && (getSchoolBasicFormControls['shippingState'].dirty || getSchoolBasicFormControls['shippingState'].touched)" class="invalid-feedback">
                                      <div *ngIf="getSchoolBasicFormControls['shippingState'].errors['required']">
                                        State is required.
                                      </div>
                                    </div>
                                </div>
                                <div class="">
                                    <span class="p-float-label cus-float-label">
                                        <input class="w-full" formControlName="shippingCity" pInputText
                                            (change)="onValueChange()" />
                                        <label>City</label>
                                    </span>
                                    <div *ngIf="getSchoolBasicFormControls['shippingCity'].errors && (getSchoolBasicFormControls['shippingCity'].dirty || getSchoolBasicFormControls['shippingCity'].touched)" class="invalid-feedback">
                                      <div *ngIf="getSchoolBasicFormControls['shippingCity'].errors['required']">
                                        City is required.
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="">
                                <span class="p-float-label cus-float-label">
                                    <input class="w-full" formControlName="email" pInputText (change)="onValueChange()" />
                                    <label>Email</label>
                                </span>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="d-flex gap-2">
                                <div class="">
                                    <span class="p-float-label cus-float-label">
                                        <input class="w-full" formControlName="shippingAddress" pInputText
                                            (change)="onValueChange()" />
                                        <label>Street Address</label>
                                    </span>
                                    <div *ngIf="getSchoolBasicFormControls['shippingAddress'].errors && (getSchoolBasicFormControls['shippingAddress'].dirty || getSchoolBasicFormControls['shippingAddress'].touched)" class="invalid-feedback">
                                      <div *ngIf="getSchoolBasicFormControls['shippingAddress'].errors['required']">
                                        Address is required.
                                      </div>
                                    </div>
                                </div>
                                <div class="">
                                    <span class="p-float-label cus-float-label">
                                        <input class="w-full" formControlName="shippingPostCode" pInputText
                                            (change)="onValueChange()" />
                                        <label>Zip</label>
                                    </span>
                                    <div *ngIf="getSchoolBasicFormControls['shippingPostCode'].errors && (getSchoolBasicFormControls['shippingPostCode'].dirty || getSchoolBasicFormControls['shippingPostCode'].touched)" class="invalid-feedback">
                                      <div *ngIf="getSchoolBasicFormControls['shippingPostCode'].errors['required']">
                                        Zipcode is required.
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <div class="">
                                <span class="p-float-label cus-float-label">
                                    <input class="w-full" appNumbersOnly formControlName="phone" pInputText (change)="onValueChange()" />
                                    <label>Phone</label>
                                </span>
                            </div>
                        </div>

                        <div class="col-6">
                            <div>
                                <span class="p-float-label cus-float-label">
                                    <p-dropdown appendTo="body" class="w-full cmn-dropdown" formControlName="administratorRepId"
                                        [options]="representativeList" optionLabel="fullName" optionValue="key"
                                        placeholder="Select Rep" (onChange)="onValueChange()"></p-dropdown>
                                    <label for="country-float-label"> Primary {{ (user?.studio?.studioName || '') | titlecase}} Rep
                                    </label>
                                </span>
                                <div *ngIf="getSchoolBasicFormControls['administratorRepId'].errors && (getSchoolBasicFormControls['administratorRepId'].dirty || getSchoolBasicFormControls['administratorRepId'].touched)"
                                    class="invalid-feedback">
                                    <div *ngIf="getSchoolBasicFormControls['administratorRepId'].errors['required']">
                                        primary rep needed
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <p-dropdown appendTo="body" class="w-full cmn-dropdown" formControlName="districtID"
                                    [options]="districtList" optionLabel="studioName" optionValue="key"
                                    placeholder="Select District" (onChange)="onValueChange()"></p-dropdown>
                                <label for="country-float-label"> District </label>
                            </span>
                        </div>

                        <div class="col-6">
                            <div class="position-relative w-full h-full">
                                <div (click)="imageInput.click()" class="image-doc-uploader">
                                    <div (dragover)="onFileSelected($event)" (drop)="onFileSelected($event)">
                                        <input style="display: none;" class="image-upload-input" #imageInput type="file"
                                            accept="image/jpeg,image/png,image/jpg"
                                            (change)="onFileSelected($event); onValueChange();">
                                        <div *ngIf="!logoPreview" class="drop-container">
                                            <svg width="40" height="40" viewBox="0 0 40 40" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_6223_1720)">
                                                    <path
                                                        d="M33.3272 14.7033C31.4785 7.33865 24.0097 2.86711 16.6451 4.71581C10.8898 6.16057 6.71076 11.1348 6.28018 17.053C2.19368 17.7269 -0.572818 21.5859 0.101093 25.6724C0.700168 29.3054 3.8481 31.966 7.53005 31.9513H13.7794V29.4516H7.53005C4.76894 29.4516 2.53059 27.2133 2.53059 24.4522C2.53059 21.6911 4.76894 19.4527 7.53005 19.4527C8.22036 19.4527 8.77991 18.8932 8.77991 18.2028C8.77366 11.9903 13.8049 6.949 20.0174 6.94283C25.3953 6.93744 30.0247 10.7394 31.065 16.0156C31.1677 16.5424 31.5952 16.9449 32.1274 17.0155C35.5443 17.5021 37.9198 20.6665 37.4333 24.0834C36.9964 27.1516 34.3766 29.4362 31.2774 29.4516H26.278V31.9513H31.2774C36.1094 31.9367 40.0146 28.0078 39.9999 23.1758C39.9877 19.1536 37.2346 15.6578 33.3272 14.7033Z"
                                                        fill="#071871" />
                                                    <path
                                                        d="M19.142 19.8146L14.1426 24.8141L15.9049 26.5764L18.7796 23.7142V35.7004H21.2793V23.7142L24.1415 26.5764L25.9038 24.8141L20.9043 19.8146C20.4168 19.33 19.6296 19.33 19.142 19.8146Z"
                                                        fill="#071871" />
                                                </g>
                                                <defs>
                                                    <clipPath id="clip0_6223_1720">
                                                        <rect width="40" height="40" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <label for="fileInput" class="drop-label pt-3">Upload Your Logo</label>
                                        </div>
                                    </div>
                                </div>
                                <div *ngIf="logoPreview" class="preview-img">
                                    <img [src]="logoPreview">
                                </div>
                                <p-button *ngIf="logoPreview" (onClick)="removeLogo()" icon="pi pi-times"
                                    class="remove-btn" [rounded]="true" severity="danger"></p-button>
                            </div>
                        </div>

                        <div class="col-6">
                          <span class="p-float-label cus-float-label">
                              <p-dropdown formControlName="timezone" appendTo="body" class="w-full cmn-dropdown" [options]="timeListByCountry" optionLabel="text" optionValue="text"
                                  placeholder="Select Country"></p-dropdown>
                              <label for="country-float-label">Timezone</label>
                          </span>
                          <div *ngIf="getSchoolBasicFormControls['timezone'].errors && (getSchoolBasicFormControls['timezone'].dirty || getSchoolBasicFormControls['timezone'].touched)"
                            class="invalid-feedback">
                            <div *ngIf="getSchoolBasicFormControls['timezone'].errors['required']">
                                Timezone is required
                            </div>
                          </div>
                        </div>

                        <div class="col-12 mt-30px mx-auto">
                            <div class="d-flex gap-3 justify-content-center max-w-400px mx-auto">
                                <!-- <p-button (onClick)="goBack()" class="cmn-btn-secondary rounded-full" label="Cancel"></p-button> -->
                                <p-button [disabled]="isBtnDisable" (onClick)="checkEmailValidation()"
                                    class="cmn-btn-primary rounded-full" label="Save"></p-button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </p-tabPanel>
        <p-tabPanel [ngClass]="{ 'active-tab justify-content-start': activeIndex === 1 }" header="Contact">

            <div class="card mt-3">
              <p-table class="cmn-table" [value]="representativeList" [tableStyle]="{ 'min-width': '50rem' }">
                  <ng-template pTemplate="header" >
                      <tr>
                          <th class="text-center">Name</th>
                          <th class="text-center">Visible to school</th>
                          <th class="text-center">Role shown to school</th>
                          <th class="text-center">Is Primary Contact</th>
                      </tr>
                  </ng-template>
                <ng-template pTemplate="body" let-representative let-i="rowIndex">
                    <tr>
                        <td class="text-center">{{ representative?.meta?.firstName + ' ' + representative?.meta?.lastName || '-'}}</td>
                        <td class="text-center">
                            <p-inputSwitch class="cmn-toggle" (onChange)="updateVisible(representative)"
                                [(ngModel)]="representative.isVisibleToSchool"></p-inputSwitch>
                        </td>
                        <td class="text-center">
                            <ng-container *ngIf="representative.isVisibleToSchool">
                                <span class="p-float-label cus-float-label input-sm max-w-250px mx-auto">
                                    <input class="w-full" placeholder="Role" pInputText (change)="updateVisible(representative)"
                                        [(ngModel)]="representative.role" />
                                </span>
                            </ng-container>
                        </td>
                        <td class="text-center">
                            <ng-container *ngIf="representative.isVisibleToSchool">
                                <div class="checkboxes__item">
                                    <label class="custom-checkbox style-d">
                                        <input type="checkbox" [(ngModel)]="representative.isPrimaryContact"
                                            (change)="this.onChangeCheckBoxVal(representative);" id="{{'primary'+i}}" />
                                        <div class="custom-checkbox__checkmark"></div>
                                        <div class="custom-checkbox__body">Set As Primary</div>
                                    </label>
                                </div>
                            </ng-container>
                        </td>
                    </tr>
                </ng-template>
                  <ng-template pTemplate="emptymessage" let-columns>
                      <tr *ngIf="isLoading">
                          <td><p-skeleton></p-skeleton></td>
                          <td><p-skeleton></p-skeleton></td>
                          <td><p-skeleton></p-skeleton></td>
                          <td><p-skeleton></p-skeleton></td>
                      </tr>
                      <tr *ngIf="!isLoading" class="tr-h-50px">
                          <td colspan="7" style="border-radius: 0 0 16px 16px;">
                              <div class="text-center">
                                  {{ 'No any contact found' }}
                              </div>
                          </td>
                      </tr>
                  </ng-template>
              </p-table>
            </div>

            <div class="row">
                <div class="col-12 d-flex justify-content-end">
                    <p-button class="cmn-btn-primary rounded-full mt-3" label="+Add" (click)="openAddEditRepresentativeDialog()"></p-button>
                </div>
            </div>
            <div class="card mt-3">
                <p-table class="cmn-table"  [value]="contactList" [tableStyle]="{ 'min-width': '50rem' }">
                    <ng-template pTemplate="header" >
                        <tr>
                            <th class="text-center">Title</th>
                            <th class="text-center">First Name</th>
                            <th class="text-center">Last Name</th>
                            <th class="text-center">Phone</th>
                            <th class="text-center">E-mail</th>
                            <!-- <th class="text-center">Access</th> -->
                            <th class="text-center">Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-contact let-i="rowIndex" >
                        <tr>
                            <td class="text-center">{{ contact?.meta?.title || '-'}}</td>
                            <td class="text-center">{{ contact?.meta?.firstName || '-' }}</td>
                            <td class="text-center">{{ contact?.meta?.lastName || '-' }}</td>
                            <td class="text-center">
                                {{ contact?.meta?.phone
                                 ? (contact.meta.phone.startsWith('+')
                                   ? contact.meta.phone
                                    : '+1' + contact.meta.phone)
                                  : '-' }}
                              </td>
                            <td class="text-center">{{ contact?.meta?.email || '-' }}</td>
                            <!-- <td class="text-center">{{ contact.access || '-' }}</td>   -->
                            <td class="text-center">
                                <p-button (click)="selectedIndex = i; filterActionButton(contact); menu.toggle($event);" class="cmn-btn-secondary action-button flex-grow" label="Action"></p-button>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="emptymessage" let-columns>
                        <tr *ngIf="isLoading">
                            <td><p-skeleton></p-skeleton></td>
                            <td><p-skeleton></p-skeleton></td>
                            <td><p-skeleton></p-skeleton></td>
                            <td><p-skeleton></p-skeleton></td>
                            <td><p-skeleton></p-skeleton></td>
                            <td><p-skeleton></p-skeleton></td>
                            <!-- <td><p-skeleton></p-skeleton></td> -->
                        </tr>
                        <tr *ngIf="!isLoading" class="tr-h-50px">
                            <td colspan="7" style="border-radius: 0 0 16px 16px;">
                                <div class="text-center">
                                    {{ 'No any contact found' }}
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </p-tabPanel>
        <p-tabPanel [ngClass]="{ 'active-tab': activeIndex === 2 }" header="Technical Info">
            <form [formGroup]="schoolTechnicalForm" class="form-card">
                <div class="row gap-y-30px justify-content-center">
                    <div class="col-6">
                        <span class="p-float-label cus-float-label">
                            <p-dropdown formControlName="syncSource" appendTo="body" optionValue="value"
                                optionLabel="name" class="w-full cmn-dropdown" [options]="sources" [disabled]="isDisabledSyncSource"
                                placeholder="Select Source" (onChange)="patchTechnicalFormVal(); onValueChange(); updateFormValidator();"></p-dropdown>
                            <label for="country-float-label"> Source </label>
                        </span>
                    </div>

                    <div class="row gap-y-30px" *ngIf="(checkSourceType(['aeries']))">
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input formControlName="syncBaseUrl" class="w-full" pInputText
                                    (change)="onValueChange()" />
                                <label>Aeries URL</label>
                            </span>
                            <div *ngIf="schoolTechnicalForm.get('syncBaseUrl').invalid && schoolTechnicalForm.get('syncBaseUrl').touched"
                                class="invalid-feedback">
                                    Aeries URL required
                            </div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input formControlName="sourceId" class="w-full" pInputText
                                    (change)="onValueChange()" />
                                <label>School Code</label>
                            </span>
                            <div *ngIf="schoolTechnicalForm.get('sourceId').invalid && schoolTechnicalForm.get('sourceId').touched"
                                class="invalid-feedback">
                                School code required
                            </div>
                        </div>
                        <div class="col-6">
                            <span class="p-float-label cus-float-label">
                                <input formControlName="certificate" class="w-full" pInputText
                                    (change)="onValueChange()" />
                                <label>Certificate</label>
                            </span>
                            <div *ngIf="schoolTechnicalForm.get('certificate').invalid && schoolTechnicalForm.get('certificate').touched"
                                class="invalid-feedback">
                                  Certificate required
                            </div>
                        </div>
                    </div>

                    <p-tabView *ngIf="(checkSourceType(['oneRoster']))" [(activeIndex)]="activeSubIndex" (onChange)="onSubTabChange($event)"
                        class="custom-tabview secondary w-full d-flex flex-col oneRoster-config-tabPanel">
                        <p-tabPanel header="oneRoster-config">
                            <div class="row gap-y-30px">
                                <!-- Base URL -->
                                <div class="col-6">
                                  <span class="p-float-label cus-float-label">
                                      <input formControlName="syncBaseUrl" class="w-full" pInputText (change)="onValueChange()" />
                                      <label> OneRoster URL </label>
                                  </span>
                                  <div *ngIf="schoolTechnicalForm.get('syncBaseUrl').invalid && schoolTechnicalForm.get('syncBaseUrl').touched"
                                      class="invalid-feedback">
                                        OneRoster URL required
                                  </div>
                                </div>
                                <!-- School Code -->
                                <div class="col-6">
                                    <span class="p-float-label cus-float-label">
                                        <input formControlName="sourceId" class="w-full" pInputText
                                            (change)="onValueChange()" />
                                        <label>School Code</label>
                                    </span>
                                    <div *ngIf="schoolTechnicalForm.get('sourceId').invalid && schoolTechnicalForm.get('sourceId').touched"
                                        class="invalid-feedback">
                                        School code required
                                    </div>
                                </div>
                          
                                <!-- Token URL -->
                                <div class="col-6">
                                    <span class="p-float-label cus-float-label">
                                        <input formControlName="syncTokenUrl" class="w-full" pInputText
                                            (change)="onValueChange()" />
                                        <label> Token Endpoint URL </label> 
                                    </span>
                                    <div *ngIf="schoolTechnicalForm.get('syncTokenUrl').invalid && schoolTechnicalForm.get('syncTokenUrl').touched"
                                        class="invalid-feedback">
                                          Token endpoint URL required
                                    </div>
                                </div>
                                
                                <!-- Client ID -->
                                <div class="col-6">
                                    <span class="p-float-label cus-float-label">
                                        <input formControlName="clientID" class="w-full" pInputText
                                            (change)="onValueChange()" />
                                        <label>Consumer(Client) ID</label>
                                    </span>
                                    <div *ngIf="schoolTechnicalForm.get('clientID').invalid && schoolTechnicalForm.get('clientID').touched"
                                        class="invalid-feedback">
                                        Consumer(Client) ID required
                                    </div>
                                </div>
                                <!-- Client Secret -->
                                <div class="col-6">
                                    <span class="p-float-label cus-float-label">
                                        <input formControlName="clientSecret" class="w-full" pInputText type="password"
                                            autocomplete="off" readonly onfocus="this.removeAttribute('readonly');"
                                            (change)="onValueChange()" />
                                        <label>Consumer(Client) Secret</label>
                                    </span>
                                    <div *ngIf="schoolTechnicalForm.get('clientSecret').invalid && schoolTechnicalForm.get('clientSecret').touched"
                                        class="invalid-feedback">
                                        Consumer(Client) secret required
                                    </div>
                                </div>
                            </div>
                        </p-tabPanel>
                        <p-tabPanel header="oneRoster-key-mapping" [disabled]="schoolTechnicalForm.invalid">
                            <div class="col-12 mb-4 p-0">
                                <span class="p-float-label cus-float-label">
                                    <p-dropdown formControlName="individual" [options]="individualList" appendTo="body" optionLabel="optionLabel"
                                        optionValue="fullName" class="w-full cmn-dropdown" (onChange)="onIndividualChange($event.value)"></p-dropdown>
                                    <label for="Source-float-label">Sample Individual</label>
                                </span>
                            </div>
                            <div class="row col-12 mb-2" *ngIf="oneRosterKeyList?.length > 0">
                                <div class="col-4 px-10 pl-2">
                                    <h4 class="m-1">OneRoster Field</h4>
                                </div>
                                <div class="col-4 pl-3">
                                    <h4 class="m-1">Sample</h4>
                                </div>
                                <div class="col-4 px-10">
                                    <h4 class="m-1">High5 Field</h4>
                                </div>
                            </div>
                            <div class="row gap-y-30px keyMapping-container" *ngIf="oneRosterKeyList.length > 0">
                                <div class="d-flex col-12 align-items-center" *ngFor="let item of oneRosterKeyList; let i = index">
                                    <div class="left-sec col-4">
                                        <div class="label-contain">
                                            <label>{{ item.oneRosterField }}</label>
                                        </div>
                                    </div>
                                    <div class="col-4 individual-data pl-2">
                                        <label>{{ item.sample }}</label>
                                    </div>
                                    <div class="right-sec col-4 px-0">
                                        <div>
                                            <p-dropdown formControlName="{{ 'dropdown' + i }}" appendTo="body" class="w-full cmn-dropdown"
                                                [options]="high5Fields" placeholder="-" optionLabel="title" optionValue="property" 
                                                [showClear]="true" (onChange)="checkValidation()">
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="error-msg col-12" *ngIf="isInvalidMapping">
                                Note: At least one field must be mapped to the High5 Individual ID.
                            </div>
                        </p-tabPanel>
                    </p-tabView>
        
                    <div class="col-12 mx-auto">
                        <div class="d-flex gap-3 max-w-400px mx-auto justify-content-center">
                            <!-- <p-button (onClick)="goBack()" class="cmn-btn-secondary rounded-full" label="Cancel"></p-button> -->
                            <p-button (onClick)="onSubmit('technical', schoolTechnicalForm)"
                                class="cmn-btn-primary rounded-full min-w-200px">Save</p-button>
                        </div>
                    </div>
                </div>
            </form>
        </p-tabPanel>
        <!-- <p-tabPanel  [ngClass]="{ 'active-tab': activeIndex === 3 }" header="Budget">
            <div class="form-card">
                <div class="row">
                    <div class="col-6">
                        <span class="p-float-label cus-float-label">
                            <input class="w-full" pInputText />
                            <label>Service Item Budget</label>
                        </span>
                    </div>
                    <div class="col-6">
                        <span class="p-float-label cus-float-label">
                            <input class="w-full" pInputText />
                            <label>Remaining Amount</label>
                        </span>
                    </div>
                </div>
                <div class="col-12 mt-30px mx-auto">
                    <div class="d-flex gap-3 max-w-400px mx-auto justify-content-center">
                        <p-button class="cmn-btn-primary rounded-full" label="Save"></p-button>
                    </div>
                </div>
            </div>
        </p-tabPanel> -->
    </p-tabView>
</div>
<p-tieredMenu class="cus-tieredMenu" #menu [model]="filteredActionList" [popup]="true">
    <ng-template let-item pTemplate="item">
        <div class="d-flex align-items-center gap-2 w-full h-full p-2" [ngClass]="{'active-menu' : item.hovered}"
            (mouseover)="item.hovered = true" (mouseout)="item.hovered = false">
            <span class="custom-icon">
                <img class="w-22 h-22" [src]="item.icon" alt="">
            </span>
            <span>{{ item.label }}</span>
        </div>
    </ng-template>
</p-tieredMenu>
<p-confirmDialog class="cus-confirmDialog"></p-confirmDialog>
<p-dialog class="cmn-dialog contact-dialog" [header]="isSetPw ? 'Set Your Password' : isEdit ? 'Edit Contact': 'Add Contact' "
    [closeOnEscape]="false" [(visible)]="fileDialog" [modal]="true"
    [style]="{'max-width.px': '800', 'width': '100%', 'box-shadow': 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px', 'border-radius': '16px'}"
    [draggable]="false" [resizable]="false">
    <app-edit-school-info [isFromSetPw]="isSetPw" [schoolData]="schoolData" [contactData]="contactData" (closeModelEvent)="closeModel($event)"></app-edit-school-info>
</p-dialog>

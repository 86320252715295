<p-toast></p-toast>
<div class="cmn-container">
    <div class="name-header">
        <div class="album-name">
            <span class="p-float-label cus-float-label">
                <input pInputText #albumNameIp id="username" class="w-full" [(ngModel)]="albumName" (ngModelChange)="onValueChanged()"/>
                <label for="username">Album Name</label>
            </span>

            <span class="p-float-label cus-float-label">
                <p-dropdown [options]="imageClassList" [(ngModel)]="selectedImageClass" placeholder="Select Image class" class="cmn-dropdown" optionLabel="name" inputId="float-label" (ngModelChange)="onValueChanged()"></p-dropdown>
                <label for="float-label">Image Class</label>
            </span>
            <div class="p-float-label cus-float-label max-w-250px">
                <p-dropdown [(ngModel)]="orgId" [disabled]="!isFromAdd" [options]="orgList" class="cmn-dropdown" optionLabel="orgName" optionValue="key" placeholder="Select Organizations" (onChange)="onOrgChange(orgId)"></p-dropdown>
                <label for="Source-float-label">Organizations</label>
            </div>
        </div>
        <div class="d-flex justify-content-end">
            <p-button label="Submit" class="cmn-btn-primary rounded-full" (click)="updateDataInDB()"></p-button>
        </div>
    </div>

    <div class="meta-content">
        <div class="keyword">
            <p>Keywords:</p> <p-chips [(ngModel)]="keywords" (ngModelChange)="dupCheckKeyWord(); onValueChanged()" separator="," class="keyword-chips" placeholder="Enter keywords"></p-chips>
        </div>
        <div class="keyword d-flex flex-col">
            <div class="header d-flex align-items-center gap-16px">
                <p>Related Events:</p> <p-button (click)="menu.toggle($event)" class="cmn-btn-primary btn-with-icon">
                    <img class="w-h-20" src="../../../../assets/svg/add -icon.svg" alt="">
                    Add New
                </p-button>
            </div>
            <div class="related-event d-flex gap-10px over">
                <div class="event-chips d-flex align-items-center gap-10px" *ngFor="let event of relatedEvent; let i = index"  (click)="!isFromAdd && navigateToEvent(event)">
                    <p class="chips">{{ event.eventName }}</p> <i class="pi pi-times" (click)="addRemoveRelatedEvents(event.eventId, false, i)"></i>
                </div>
            </div>
        </div>
        <div class="content-section">
            <div class="sqr w-50">
                <span> {{ albumMetaData && albumMetaData.imageCount ? albumMetaData.imageCount : 0 }} </span>
                <p>Files</p>
            </div>
            <div class="sqr" (click)="showDialog()">
                <img src="assets/svg/upload.svg">
                <p>Upload Files</p>
            </div>
            <!-- <div class="sqr">
                <img src="assets/svg/download-file.svg">
                <p>Download Files</p>
            </div> -->
            <div class="sqr" (click)="deleteAlbum()">
                <img src="assets/svg/delete-album.svg">
                <p>Delete Album</p>
            </div>
        </div>
    </div>

    @if (albumMetaData?.imageCount > 0) {
        <div class="image-section">
            <ng-container *ngFor="let image of imageContentList; let i = index">
                <div class="image-outer" *ngIf="!image.meta.isDeleted">
                    <div class="image">
                        <div class="close-icon" (click)="deleteImage($event, i)">
                            <img class="img" src="assets/svg/trash.svg" alt="">
                        </div>
                        <img [class]="image.content.isImage ? 'img' : 'file'" [src]="image.content.isImage ? image.content.photoUrl :'assets/svg/file.svg' " alt="">
                        <p> {{ image.content.originalFilename }} </p>
                    </div>
                </div>
            </ng-container>
        </div>
    } @else {
        <div class="no-img">
            <img src="../../../../assets/svg/no-img-found.png">
        </div>
    }
</div>

<p-dialog class="cmn-dialog image-upload-dialog" header="Upload Files" [(visible)]="fileDialog" [modal]="true"
    [style]="{'max-width.px': '600', 'width': '100%', 'box-shadow': 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px', 'border-radius': '16px'}" [draggable]="false" [resizable]="false">
    <app-file-upload-dialog [currentAlbum]="currentAlbum" [isAlbumTypeDisable]="(!isFromAdd && (albumMetaData?.imageCount > 0))" (closeModelEvent)="closeModel($event); onValueChanged()"></app-file-upload-dialog>
</p-dialog>

<p-dialog class="cmn-dialog image-upload-dialog" [(visible)]="loaderDialog" [modal]="true"
    [style]="{'max-width.px': '600', 'width': '100%', 'box-shadow': 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px', 'border-radius': '16px'}" [draggable]="false" [resizable]="false">
    <app-loader></app-loader>
</p-dialog>

<div class="card flex justify-content-center">
    <p-dialog class="cus-dialog error-dialog" [header]="alertHeader" [(visible)]="showAlert" [modal]="true" [draggable]="false"
        [style]="{ 'max-width': '500px', 'width': '100%' }" [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }">
        <p class="m-0">
           {{ alertMessage }}
        </p>
        <ng-template pTemplate="footer">
            <p-button class="cmn-btn-primary w-auto" (click)="showAlert = false" label="Ok" pAutoFocus
                [autofocus]="true"></p-button>
        </ng-template>
    </p-dialog>
</div>

<p-confirmDialog class="cus-confirmDialog"></p-confirmDialog>

<p-tieredMenu class="cus-tieredMenu" #menu [model]="allEventsList" [popup]="true" (change)="onValueChanged()">
    <ng-template let-event pTemplate="event">
        <div class="d-flex align-items-center gap-2 w-full h-full p-2" (click)="addRemoveRelatedEvents(event.key, true)" *ngIf="!event.isAdded">
            <span>{{ event.meta.displayName }}</span>
        </div>
    </ng-template>
</p-tieredMenu>
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { SharedModule } from '../../shared.module';
import lodash from 'lodash';
import { ConfirmationService, MessageService } from 'primeng/api';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonServiceService } from '../../services/common-service.service';
import { AlbumType } from '../../constants/enums';

@Component({
  selector: 'app-file-upload-dialog',
  standalone: true,
  imports: [
    SharedModule,
    CheckboxModule
  ],
  templateUrl: './file-upload-dialog.component.html',
  styleUrl: './file-upload-dialog.component.scss'
})
export class FileUploadDialogComponent {
  imageFiles: File[] = [];
  csvFile: File;
  @Output() closeModelEvent = new EventEmitter<any>();
  @ViewChild('imageInput') imageInput!: ElementRef<HTMLInputElement>;
  @ViewChild('csvInput') csvInput!: ElementRef<HTMLInputElement>;
  useForID: string[] = [];
  @Input() isAlbumTypeDisable: boolean = false
  @Input() currentAlbum: any
  selectedAlbumType: AlbumType = AlbumType.GENERAL

  albumTypes: any[] = [
    { label: 'Used for ID', value: AlbumType.ID },
    { label: 'Used for Yearbook', value: AlbumType.YEARBOOK },
    { label: 'General', value: AlbumType.GENERAL },
  ];

  constructor (
    private messageService: MessageService,
    private commonFun: CommonServiceService,
    private confirmationService: ConfirmationService
  ) {}

  ngOnChanges() {
    if (this.currentAlbum) {
      this.selectedAlbumType = this.currentAlbum?.meta?.albumType
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    // event.stopPropagation();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
    }
  }

  // onDragLeave(event: DragEvent) {
  //   event.preventDefault();
  //   event.stopPropagation();
  // }

  onDrop(event: DragEvent, format: string = '') {
    event.preventDefault();
    // event.stopPropagation();
    if (event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';

      let filteredFiles: any[] = [];
      if (format == 'csv') filteredFiles = lodash.filter(event.dataTransfer.files, (file: any) => (format == 'csv' && file.type != 'text/csv' && file.type != 'text/plain' && file.type != 'application/vnd.ms-excel'));
      // else filteredFiles = lodash.filter(event.dataTransfer.files, (file: any) => (format != 'csv' && (file.type != 'image/jpeg' && file.type != 'image/png' && file.type != 'image/jpg')));

      if (filteredFiles.length > 0) {
        window.alert(`Please select valid ${(format == 'csv') ? 'CSV' : 'images'} file to upload`);
      } else if (format == 'csv') {
        if (event.dataTransfer.files.length > 1) window.alert('Only one file is allowed to be upload.\nFirst file is selected');
        this.onFileSelected(event, format);
      } else if (format == 'images') {
        this.onFileSelected(event, format);
      }
    }
  }

  onFileSelected(event: any, format: string = '') {
    let files = event.target.files || event.dataTransfer.files;
    const disallowedExtensions: string[] = [
        ".EXE", ".PIF", ".APPLICATION", ".GADGET", ".MSI", ".MSP",
        ".COM", ".SCR", ".HTA", ".CPL", ".MSC", ".JAR", ".BAT",
        ".CMD", ".VB", ".VBS", ".VBE", ".JS", ".JSE", ".WS", ".WSF",
        ".WSC", ".WSH", ".PS1", ".PS1XML", ".PS2", ".PS2XML", ".PSC1",
        ".PSC2", ".MSH", ".MSH1", ".MSH2", ".MSHXML", ".MSH1XML",
        ".MSH2XML", ".SCF", ".LNK", ".INF", ".REG", ".DOC", ".XLS",
        ".PPT", ".DOCM", ".DOTM", ".XLSM", ".XLTM", ".XLAM", ".PPTM",
        ".POTM", ".PPAM", ".PPSM", ".SLDM"
    ];

    const filteredFiles: File[] = [];
    for (const file of files) {
        const extension = file.name.split('.').pop()?.toUpperCase();
        if (!extension || disallowedExtensions.includes(`.${extension}`)) {
            this.messageService.add({
              severity: 'error',
              summary: 'File Not Supported',
              detail: `The file '${file.name}' is not supported.`,
              life: 5000
            });
            continue;
        }
        filteredFiles.push(file);
    }

    if (format == 'csv') {
      this.addFiles(filteredFiles, format);
    } else if (format == 'images') {
      this.addFiles(filteredFiles, format);
    }
 }

  addFiles(files: File[], format: string = '') {
    if (format == 'csv') {
      this.csvFile = files[0];
    } else {
      this.imageFiles = files;
    }
  }

  closeModel(isCancelBtn: boolean = false) {
    if (isCancelBtn) {
      this.closeModelEvent.emit();
    } else {
      if (!this.selectedAlbumType) {
        this.openConfirmationDialog(
          'Required Field',
          'Please select an album type.',
          'OK',
          '',
          false,
          true,
          false
        );
        return
      }

      if (
        this.imageInput &&
        this.imageFiles.length == 0 &&
        ((this.csvInput && !this.csvFile) || (!this.csvInput && !this.csvFile))
      ) {
        this.openConfirmationDialog(
          'Required Field',
          'Please select at least one image or CSV to upload.',
          'OK',
          '',
          false,
          true,
          false
        );
      } else {
        this.closeModelEvent.emit({
          imageFiles: this.imageFiles,
          csvFile: this.csvFile,
          albumType: this.selectedAlbumType,
        });
      }
    }
    this.imageFiles = [];
    this.csvFile = null;
  }

  downloadSampleFile() {
    this.commonFun.downloadFile('assets/sampleCSV/SampleAlbumIndex.csv', "Sample_CSV.csv");
  }

  openConfirmationDialog(
    title: string,
    msg: string,
    positiveBtnTxt: string,
    negativeBtnTxt: string,
    dismissOnOutsideClick: boolean = false,
    showAcceptBtn: boolean = true,
    showCloseBtn: boolean = false,
    positiveBtnHandler?: () => void,
    negativeBtnHandler?: () => void
  ): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.confirmationService.confirm({
        message: msg,
        header: title,
        acceptLabel: positiveBtnTxt,
        rejectLabel: negativeBtnTxt,
        accept: () => {
          if (positiveBtnHandler) positiveBtnHandler();
          resolve(true);
        },
        reject: () => {
          if (negativeBtnHandler) negativeBtnHandler();
          resolve(false);
        },
        rejectVisible: showCloseBtn,
        acceptVisible: showAcceptBtn,
        blockScroll: true,
        closeOnEscape: true,
        dismissableMask: dismissOnOutsideClick,
        acceptIcon: 'none',
        rejectIcon: 'none',
        defaultFocus: 'none'
      });
    });
  }
}